// Website theme colors
$primaryWhiteColor: var(--primary-white-color, #ffffff);
$primaryDarkColor:var(--primary-dark-color, #1F2547);
$primarylightDarkColor:var(--primary-light-dark-color, #273070);
$primaryBlueColor:var(--primary-blue-color, #27316F);
$primaryGrayColor:var(--primary-grey-color, #E3E8F2);


$textHoverColor:var(--link-hover-color, #4E8AFF);
$primaryActiveColor:var(--link-hover-color, #e21e25);

$errorColor: var(--error-color, #EB5757);
$validColor: var(--valid-color, #47CF5C);