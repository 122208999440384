@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import "colors";
@import "mixins";
@import url('bootstrap/dist/css/bootstrap.min.css');
@import url('owl/owl.carousel.scss');
@import url('owl/owl.theme.default.scss');


/** Global Styles
================================================**/

// font family
/*
font-family: 'Barlow', sans-serif;
Thin 100
ExtraLight 200
Light 300
Regular 400
Medium 500
SemiBold 600
Bold 700
ExtraBold 800
Black 900
*/

* {
	margin: 0;
	padding: 0;
}

a {
	color: $primaryBlueColor;
	text-decoration: none;
}

a:hover {
	color: $primaryActiveColor;
}


a:focus,
button:focus,
.btn:focus,
.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus {
	outline: none;
}

img {
	max-width: 100%;
}

button,
input,
label,
select,
textarea {
	resize: none;
}

.MuiButton-contained {
	box-shadow: none !important;

}

.MuiButton-root {
	text-transform: none !important;
}

*,
:after,
:before,
html,
body {
	@include box-sizing(border-box);
	font-family: 'Barlow', sans-serif;
}


::-moz-selection {
	background-color: $primaryDarkColor;
	color: $primaryWhiteColor;
}

::-webkit-selection {
	background-color: $primaryDarkColor;
	color: $primaryWhiteColor;
}

::selection {
	background-color: $primaryDarkColor;
	color: $primaryWhiteColor;
}

p {
	margin: 0;
}

ul {
	padding: 0;
	margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
}

.text-right {
	text-align: right !important;
}

.text-center {
	text-align: center !important;
}

.p-t-0 {
	padding-top: 0 !important;
}

.p-b-0 {
	padding-bottom: 0 !important;
}

.p-l-0 {
	padding-left: 0 !important;
}

.p-r-0 {
	padding-right: 0 !important;

}

.p-t-5 {
	padding-top: 5px !important;
}

.p-b-5 {
	padding-bottom: 5px !important;
}

.p-t-10 {
	padding-top: 10px !important;
}

.p-b-10 {
	padding-bottom: 10px !important;
}

.p-t-15 {
	padding-top: 15px !important;
}

.p-b-15 {
	padding-bottom: 15px !important;
}

.p-b-20 {
	padding-bottom: 20px !important;
}

.p-b-30 {
	padding-bottom: 30px !important;
}

.m-t-10 {
	margin-top: 10px !important;
}

.m-t-15 {
	margin-top: 15px !important;
}

.m-t-30 {
	margin-top: 30px !important;
}

.m-b-15 {
	margin-bottom: 15px !important;
}

.m-b-30 {
	margin-bottom: 30px !important;
}

.m-b-20 {
	margin-bottom: 20px !important;
}

.form-heading {
	font-style: normal;
	font-weight: 700;
	font-size: 30px;
	line-height: 36px;
	color: #030112;
	padding-bottom: 10px;
}

.form-desc {
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	color: #030112;
}

.form-row {
	margin: 0 -10px;
	@include flexbox;
	@include flex-wrap(wrap);
}

.form-label {
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	padding-bottom: 10px;
	color: #28305C;
	display: inline-block;
}

.w100,
.w50,
.w33,
.w66 {
	padding: 0 10px;
}

.dont-have-account {
	display: flex;
	justify-content: space-between;
}

.w100 {
	@include flex(0, 0, 100%);
}

.w50 {
	@include flex(0, 0, 50%);
}

.w33 {
	@include flex(0, 0, 33.33%);
}

.w66 {
	@include flex(0, 0, 66.66%);
}

.form-field {
	margin-bottom: 20px;
}

.d-flex {
	@include flexbox;
}

html,
body {
	background-color: $primaryWhiteColor;
}

.btn {
	height: 54px;
	min-width: 108px;
	border-radius: 15px;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	color: $primaryWhiteColor;
	background: $primaryBlueColor;
	padding: 0 20px;
	border: 0;
	box-shadow: none !important;
	outline: none;
	cursor: pointer;
	@include inline-flexbox;
	@include align-items(center);
	@include justify-content(center);
	@include transition(all, 0.3s, ease);

	@media only screen and (max-width: 1100px) {
		height: auto;
		width: auto;
		padding: 8px 14px;
	}

	&>.btn-icon {
		padding-right: 5px;
		display: inline-block;
		vertical-align: middle;
		font-size: 30px;
		font-weight: 400;
		line-height: 25px;
		position: relative;
		top: -2px;
	}

	&.btn-min-width-200 {
		min-width: 200px;
	}

	&:hover {
		background: $primaryActiveColor;
		color: $primaryWhiteColor;
	}

	&.btn-blue {
		color: $primaryWhiteColor;
		background: $primaryBlueColor;

		&:hover {
			background: #e21e25;
			color: $primaryWhiteColor;
		}
	}

	&.btn-grey {
		background: $primaryGrayColor;
		color: $primaryBlueColor;

		&:hover {
			background: #e21e25;
			color: $primaryWhiteColor;
		}
	}

	&.btn-white {
		background: $primaryWhiteColor;
		color: $primaryBlueColor;

		&:hover {
			background: #e21e25;
			color: $primaryWhiteColor;
		}
	}

	&.btn-sm {
		height: 44px;
		border-radius: 8px;
	}

	&.btn-transparent {
		background: transparent;
		color: $primaryBlueColor;
		padding: 0;
		height: auto;
		min-width: 0;

		&:hover {
			background: transparent;
			color: $primaryBlueColor;
		}
	}
}

.container {
	width: 100%;
	max-width: 90%;
	margin: 0 auto;
	padding: 0 15px;
}

.inner-container {
	width: 100%;
	max-width: 85%;
	margin: 0 auto;
	padding: 0 15px;
}

section {
	padding: 45px 0;
}


.dashboard-menu {
	@include flexbox;
	@include align-items(center);
	list-style-type: none;

	&>li {
		&:not(:last-child) {
			padding-right: 40px;
		}

		.menu-item {
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 28px;
			color: $primaryBlueColor;
			position: relative;
			cursor: pointer;

			&:hover {
				font-weight: 700;
			}
		}

		&.active {
			.menu-item {
				font-weight: 700;
			}
		}

		&.responsive-close {
			display: none;
			text-align: right;
			margin-bottom: 15px;
		}


	}
}

.btn-toggle {
	height: 35px;
	width: 40px;
	border: 0;
	border-radius: 8px;
	background: $primaryDarkColor;
	padding: 0 5px;
	display: none;

	span {
		display: block;
		height: 1px;
		width: 100%;
		background: $primaryWhiteColor;
		margin: 7px 0;
		@include transition(all, 0.3s, ease);
	}

}

.overlay {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.2);
	z-index: 99;
	display: none;
}

.site-header {
	.container {
		max-width: 95%;
	}
}

.top-header {
	background: $primaryBlueColor;
	box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.03);
	padding: 10px 0;
}

.top-links {
	text-align: right;

	li {
		list-style-type: none;
		display: inline-block;
		vertical-align: middle;

		a {
			color: $primaryWhiteColor;
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 28px;
			position: relative;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			padding-left: 26px;

			&:hover {
				text-decoration: underline;
			}
		}

		&:not(:last-child) {
			padding-right: 15px;
		}
	}
}

.link-icon {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	height: 15px;
	width: 15px;
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
}

.bottom-header {
	padding: 30px 0;
}

.site-header {
	box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.03);
}

.header-inner {
	@include flexbox;
	@include align-items(center);
	@include justify-content(space-between);
	@include flex-wrap(wrap);
}

.brand {
	a {
		display: flex;
	}
}

.hero-section {
	padding: 0 !important;

	.owl-dots {
		position: absolute;
		bottom: 20px;
		right: 20px;
	}

	.owl-theme .owl-dots .owl-dot.active span,
	.owl-theme .owl-dots .owl-dot:hover span {
		background: #27316F;
		opacity: 1;
	}

	.owl-theme .owl-dots .owl-dot span {
		background: #27316F;
		opacity: 0.2;
	}

}

.hero-section-inner {
	min-height: 550px;
	background-repeat: no-repeat;
	background-position: center bottom;
	background-size: cover;
	padding: 70px 0;
}

.section-heading {
	font-style: normal;
	font-weight: 600;
	font-size: 40px;
	line-height: 48px;
	color: $primaryWhiteColor;
	padding-bottom: 20px;

	&.dark {
		color: $primaryBlueColor;
	}
}

.section-sub-heading {
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	line-height: 29px;
	color: $primaryWhiteColor;

	&.dark {
		color: $primaryBlueColor;
	}
}

.section-desc {
	margin: 0;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 26px;
	color: $primaryWhiteColor;
	padding-bottom: 25px;

	&.dark {
		color: $primaryBlueColor;
	}
}

.services-list {
	margin: 0 -25px;
	@include flexbox;
	@include flex-wrap(wrap);

	li {
		list-style-type: none;
		padding: 15px 25px;
		flex: 0 0 33.33%;
	}
}

.service {
	background: #FFFFFF;
	box-shadow: 25px 44px 30px rgba(0, 0, 0, 0.01), 11px 19px 22px rgba(0, 0, 0, 0.02), 3px 5px 12px rgba(0, 0, 0, 0.02), 0px 0px 0px rgba(0, 0, 0, 0.02);
	border-radius: 20px;
	padding: 30px;
	border: 1.5px solid rgba(39, 49, 111, 0.1);

	a {
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 19px;
		color: #273070;
		display: inline-flex;
		padding-right: 20px;
		position: relative;
		@include transition(all, 0.1s, ease);

		&:hover {
			padding-right: 25px;
		}
	}
}

.service-icon {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 78px;
	width: 78px;
	background: $primaryBlueColor;
	border-radius: 20px;
	margin-bottom: 27px;
}

.service-type {
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 22px;
	color: #1F2547;
	padding-bottom: 12px;
}

.service-benifit {
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	line-height: 29px;
	color: #1F2547;
	padding-bottom: 16px;
}

.arrow {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 50%;
	right: 0;
	transform: translateY(-50%);
}


.overlay-inner {
	padding: 70px;
	border-radius: 30px;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	@include flexbox;
	@include align-items(center);
	@include justify-content(center);
	@include flex-direction(column);
}

.site-footer {
	background: $primaryBlueColor;
	box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.03);
}

.footer-links {
	text-align: right;
	padding-bottom: 15px;

	li {
		list-style-type: none;
		display: inline-block;

		a {
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 28px;
			color: #ffffff;
		}

		&:not(:last-child) {
			padding-right: 50px;
		}
	}
}

.footer-inner {
	min-height: 126px;
	padding: 15px 0;
	@include flexbox;
	@include align-items(center);
	@include justify-content(space-between);
	@include flex-wrap(wrap);
	margin: 0 -15px;
}

.footer-left,
.footer-right {
	padding: 0 15px;
}

.footer-left {
	left: 0;

	.logo {
		display: flex;
	}
}

.footer-right {
	flex: 1 1 auto;
}


.checkbox {
	margin: 0;
	display: inline-block;
	cursor: pointer;
	margin-right: 20px;

	span {
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 17px;
		min-height: 17px;
		position: relative;
		padding-left: 26px;

		&:before {
			content: "";
			height: 16px;
			width: 16px;
			border: 1px solid #707070;
			border-radius: 3px;
			display: inline-block;
			position: absolute;
			top: 0;
			left: 0;
		}
	}

	.main-head {
		font-weight: 700 !important;
		font-size: 17px;
	}

	.sub-head {
		font-weight: 700 !important;
	}

	&>input[type="checkbox"],
	&>input[type="radio"] {
		display: none;
	}

	input[type="checkbox"]:checked,
	input[type="radio"]:checked {
		&~span {
			&:before {
				background: #27316F;
				background-image: url('/assets/images/check.svg');
				background-repeat: no-repeat;
				background-position: center center;
			}
		}
	}

	&.checkbox {
		&.radio-checkbox {
			span {
				&:before {
					border-radius: 50%;
				}
			}

			input[type="checkbox"]:checked,
			input[type="radio"]:checked {
				&~span {
					&:before {
						background-image: none;
						box-shadow: inset 0px 0px 0px 3px #ffffff;
						background-color: #27316F;
					}
				}
			}
		}
	}

}

.additional-link {
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #27316F;
}

.f-control {
	height: 50px;
	width: 100%;
	border: 1px solid #E1E1E1;
	border-radius: 0;
	box-shadow: none;
	outline: none;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: $primaryBlueColor;
	padding: 0 15px;
}

.login-left-wrapper {
	max-width: 450px;
	margin: 0 auto;

	.brand {
		margin-bottom: 100px;

		@media screen and (max-width: 1536px) {
			margin-bottom: 30px;
		}
	}
}

.login-box {
	background: #FFFFFF;
	border: 1px solid rgba(0, 0, 0, 0.17);
	padding: 30px 47px;

	.form-desc {
		padding-bottom: 25px;
	}
}

.btn-square {
	height: 50px;
	min-width: 100%;
	border-radius: 0;
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	color: $primaryWhiteColor;
	background: $primaryBlueColor;
	padding: 0 20px;
	border: 0;
	cursor: pointer;
	@include inline-flexbox;
	@include align-items(center);
	@include justify-content(center);
	@include transition(all, 0.3s, ease);

	&:hover {
		background: $primaryActiveColor;
		color: $primaryWhiteColor;
	}

	&.btn-blue {
		color: $primaryWhiteColor;
		background: $primaryBlueColor;

		&:hover {
			background: #e21e25;
			color: $primaryWhiteColor;
		}
	}

	&.btn-grey {
		background: $primaryGrayColor;
		color: $primaryBlueColor;

		&:hover {
			background: #e21e25;
			color: $primaryWhiteColor;
		}
	}

	&.btn-white {
		background: $primaryWhiteColor;
		color: $primaryBlueColor;

		&:hover {
			background: #e21e25;
			color: $primaryWhiteColor;
		}
	}
}

.login-inner {
	display: flex;
	flex-wrap: wrap;
}

.login-left {
	background: #fbfcff;
	border-right: 1px solid rgba(0, 0, 0, 0.17);
}

.login-left,
.login-right {
	flex: 0 0 50%;
	overflow: hidden;

	.w100,
	.w50,
	.w33,
	.w66 {
		overflow: hidden;
	}
}

.login-right {
	padding: 20px 20px 0 20px;
}

.gallery-item {
	margin-bottom: 20px;
	position: relative;
	border: 1px solid #D4D4D4;

	img {
		height: 100%;
		width: 100%;
		object-fit: cover;
		display: flex;
	}
}

section {
	&.login-section {
		padding: 0;
	}
}

.login-left {
	padding: 80px 30px;

	@media screen and (max-width: 1536px) {
		padding: 30px;
	}
}

.dashboard-inner {
	// padding: 15px;
	padding: 20px 25px;
	background: #f8f8f8;
	border-radius: 20px 0 0 20px;
}

.d-card {
	padding: 0;
	background: #FFFFFF;
	box-shadow: 0px 0px 23px rgba(0, 0, 0, 0.02);
	border-radius: 15px;

	&.bordered {
		border: 1px solid rgba(39, 49, 111, 0.1);
	}
}

.d-card-desc {
	flex: 0 0 100%;
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 24px;
	padding: 0 20px;
	color: #28305C;
}

.contact-us-wrapper {
	max-width: 80%;
	margin: 0 auto;
}

.bid {
	background: #F8F8F8;
	border-radius: 10px;
	padding: 15px 0;
	position: relative;
	overflow: hidden;

	&:after {
		content: "";
		width: 78px;
		height: 78px;
		background: #ededf0;
		border-radius: 50%;
		position: absolute;
		bottom: -10px;
		right: -10px;
		pointer-events: none;
		z-index: 1;
	}
}

.bid-header {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	position: relative;
	z-index: 2;


	.bid-header-left {
		padding: 0 15px 10px 15px;
	}

	.bid-header-right {
		padding: 0 15px 10px 15px;
	}

}

.bid-label {
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #27316F;
}

.bid-value {
	font-style: normal;
	font-weight: 500;
	font-size: 19.7647px;
	line-height: 24px;
	color: #27316F;
}

.bid-date {
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #27316F;
}

.bid-footer {
	display: flex;
	justify-content: space-between;
	position: relative;
	z-index: 2;
	flex-wrap: wrap;

	.bid-footer-left {
		padding: 0 15px 10px 15px;
	}

	.bid-footer-right {
		padding: 0 15px 10px 15px;
	}

	.bid-price {
		font-style: normal;
		font-weight: 500;
		font-size: 32.5px;
		line-height: 39px;
		color: #27316F;
	}
}

.map-box {
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid rgba(39, 49, 111, 0.2);
	border-radius: 15px;
	padding-bottom: 80%;
	position: relative;
	overflow: hidden;

	img {
		object-fit: cover;
		width: 100%;
		height: 100%;
		border-radius: 15px;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
}

.map-desc {
	padding: 7px 0;
	background: #FFFFFF;
	border-top: 1px solid rgba(39, 49, 111, 0.2);
	box-shadow: 0px 0px 23px rgba(0, 0, 0, 0.02);
	border-radius: 0px 0px 15px 15px;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	justify-content: space-between;
	align-items: center;

	h5 {
		font-style: normal;
		font-weight: 500;
		font-size: 18px;
		line-height: 22px;
		color: #1F2547;
		padding: 0 15px;
	}

	h6 {
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 19px;
		display: flex;
		align-items: center;
		color: #1F2547;
		padding: 0 15px;
	}
}

.maps-list {
	margin: 0 -12px;
	display: flex;
	flex-wrap: wrap;

	li {
		list-style-type: none;
		padding: 0 12px;
		flex: 0 0 50%;
	}
}

.d-card-heading {
	font-style: normal;
	font-weight: 600;
	font-size: 35px;
	line-height: 31px;
	color: #27316F;
	padding: 0 20px;

	&.d-card-sub-heading {
		font-style: normal;
		font-weight: 500;
		font-size: 26px;
		line-height: 31px;
	}
}

.card-sub-heading {
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 22px;
	color: #27316F;

	span {
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 19px;
		color: #27316F;
	}
}

.d-card-header {
	padding: 15px 0;
	border-bottom: 1px solid #EEEEEE;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
}

.d-card-body {
	padding: 15px;
}

.bids-list {
	li {
		list-style-type: none;

		&:not(:last-child) {
			padding-bottom: 20px;
		}
	}
}

.d-card-actions {
	padding: 0 20px;
}

.dashboard-action.dropdown-toggle {
	background: transparent !important;
}

.dashboard-action.dropdown-toggle::after {
	display: none;
}

.banner-section {
	border-radius: 20px;
	overflow: hidden;
	height: 500px;
	position: relative;

	&>img {
		position: absolute;
		height: 100%;
		width: 100%;
		object-fit: cover;
	}
}

.static-desc {
	p {
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 19px;
		letter-spacing: 0.065em;
		color: #3D5170;

		&:not(:last-child) {
			padding-bottom: 20px;
		}
	}
}


.staticPos {
	position: static !important;
	margin: 0 !important;
	transform: none !important;
}

.d-tile {
	display: flex;
	flex-direction: column;
	box-shadow: 0px 0px 23px rgba(0, 0, 0, 0.02);
	border-radius: 20px;
	padding: 15px;
	flex: 0 0 100%;

	&.light-blue {
		background: rgba(39, 49, 111, 0.1);
	}

	&.light-red {
		background: rgba(226, 31, 37, 0.1);
	}

	&.light-green {
		background: rgba(164, 210, 157, 0.2);
	}
}

.dashboard-tiles {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -12px;

	li {
		list-style-type: none;
		flex: 0 0 25%;
		padding: 0px 12px 15px 12px;
		display: flex;
	}

	&.dashboard-tiles-full {
		li {
			flex: 0 0 100%;
		}
	}
}

.d-tile-header {
	display: flex;
	justify-content: space-between;
	margin: 0 -10px;
	flex: 1 1 auto;
}

.d-t-left,
.d-t-right {
	padding: 0 10px;
}

.d-t-left {
	align-self: flex-end;
}

.d-tile-footer {
	display: flex;
	flex-wrap: wrap;
	padding-top: 13px;

	h5 {
		font-style: normal;
		font-weight: 500;
		font-size: 35px;
		line-height: 42px;
		color: #27316F;
	}

	span {
		font-style: normal;
		font-weight: 500;
		font-size: 18px;
		line-height: 22px;
		color: #1F2547;
	}
}

.d-t-left {
	h4 {
		font-style: normal;
		font-weight: 500;
		font-size: 49.9811px;
		line-height: 60px;
		color: #27316F;
	}

	p {
		font-style: normal;
		font-weight: 500;
		font-size: 18px;
		line-height: 22px;
		color: #1F2547;
	}
}

.tile-icon {
	display: flex;
	align-items: center;
	justify-content: center;
	background: #FFFFFF;
	box-shadow: 0px 0px 38.2198px rgba(0, 0, 0, 0.02);
	height: 74px;
	width: 74px;
	border-radius: 50%;
}

.dashboard-cards-row {
	display: flex;
	margin: 0 -15px;
	flex-wrap: wrap;
}

.d-card-col-68 {
	flex: 0 0 68%;
	padding: 0 15px;
	overflow: hidden;
}

.d-card-col-32 {
	flex: 0 0 32%;
	padding: 0 15px;
	overflow: hidden;
}

.sidebar-item {
	display: block;
	background: #ffffff;
	border-radius: 4px;
	padding: 12px 10px 12px 38px;
	position: relative;

	&>h5 {
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 19px;
		color: #7F87A4;
	}

	&>span {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 15px;
		width: 15px;
		position: absolute;
		top: 50%;
		left: 10px;
		transform: translateY(-50%);
	}

	i {
		img {
			width: 14px;
		}
	}
}

.sidebar-dropdown.active .sidebar-item,
.sidebar-item:hover {
	background: #E8EBF2;
}

.sidebar-dropdown.active .sidebar-dropdown-list .sidebar-item.activeChild {
	background: #b5bccd;
}

.sidebar-dropdown.active .sidebar-item>h5,
.sidebar-item:hover>h5 {
	color: #28305C;
	font-weight: 600;
}

.sidebar-dropdown.active .sidebar-item>span svg path,
.sidebar-item:hover>span svg path {
	fill: #28305C;
}

.sidebar-item {
	display: block;
	background: #ffffff;
	border-radius: 4px;
	padding: 12px 10px 12px 38px;
	position: relative;

	&>h5 {
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 19px;
		color: #7F87A4;
	}

	&>span {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 15px;
		width: 15px;
		position: absolute;
		top: 50%;
		left: 10px;
		transform: translateY(-50%);
	}
}

.sidebar-item.active,
.sidebar-item:hover {
	background: #E8EBF2;
}

.sidebar-item.active>h5,
.sidebar-item:hover>h5 {
	color: #28305C;
}

.sidebar-item.active>span svg path,
.sidebar-item:hover>span svg path {
	fill: #28305C;
}

.dashboard-sidebar {
	width: 273px;
	background: #FFFFFF;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	padding: 11px;
	z-index: 999;
}

.dashboard-logo-wrap {
	padding: 0 0 15px 0;
	text-align: center;

	.dashboard-logo {
		img {
			width: 60%;
		}
	}
}

.sidebar-links {
	padding-top: 0px;

	li {
		list-style-type: none;
		margin-bottom: 5px;
	}
}

.dashboard {
	padding-left: 268px;
	min-height: 100vh;
	position: relative;
}

.d-card {
	&.vl-padding {
		.d-card-body {
			padding-bottom: 0;

			&>ul {
				&>li {
					padding-bottom: 20px;
				}
			}
		}
	}
}

.d-card {
	&.vl-padding {
		.d-card-body {
			padding-bottom: 0;

			&>ul {
				&>li {
					padding-bottom: 20px;
				}
			}
		}
	}
}

.table-odd-even {
	width: 100%;
	border: 0;
	border-collapse: separate;
	border-spacing: 0 10px;

	&.cellSpace15 {
		tr {
			td {
				padding: 15px;
			}
		}
	}

	tr {
		td {
			background: #F8F8F8;
			padding: 7px;
			border: 0;
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 19px;
			color: #27316F;

			&:first-child {
				border-top-left-radius: 10px;
				border-bottom-left-radius: 10px;
			}

			&:last-child {
				border-top-right-radius: 10px;
				border-bottom-right-radius: 10px;
			}
		}
	}
}

.bid-details {
	display: flex;
	flex-direction: column;
	padding-left: 55px;
	min-height: 25px;
	position: relative;
	justify-content: center;

	h4 {
		font-style: normal;
		font-weight: 500;
		font-size: 20px;
		line-height: 24px;
		color: #27316F;
		padding-bottom: 0px;
	}

	h5 {
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 19px;
		color: #27316F;
	}
}

.master-estimate {
	display: flex;
	flex-direction: column;
	padding-left: 9px;
	min-height: 25px;
	position: relative;
	justify-content: center;

	h4 {
		font-style: normal;
		font-weight: 500;
		font-size: 20px;
		line-height: 24px;
		color: #27316F;
		padding-bottom: 0px;
	}

	h6 {
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 19px;
		color: #27316F;
	}
}

.bid-icon {
	display: flex;
	height: 45px;
	width: 45px;
	object-fit: cover;
	border-radius: 4px;
	position: absolute;
	top: 0;
	left: 0;

	img {
		width: 100%;
		height: 100%;
	}
}

.bid-closing {
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: #27316F;
	text-align: right;
	padding: 0 15px;
}

.dashboard-title {
	font-style: normal;
	font-weight: 500;
	font-size: 32px;
	line-height: 38px;
	color: #27316F;
	padding-bottom: 30px;
	display: flex;
	align-items: center;
}

.search-input {
	height: 42px;
	border: 1.5px solid #CFDADD;
	border-radius: 10px;
	padding: 0 20px 0 50px;
	width: 100%;
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	outline: none;
}

.search-box {
	position: relative;
	max-width: 393px;
	width: 100%;
}

.search-icon {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 16px;
	width: 16px;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 20px;
}

.dashboard-actions .dropdown-toggle,
.dashboard-actions .dropdowns-toggle {
	padding: 0;
	width: 42px;
	min-width: 0;
	height: 42px;
	outline: none;
	box-shadow: none;
	border-radius: 10px;

	&>img {
		max-width: 100%;
		max-height: 100%;
	}

	&:after {
		display: none;
	}
}

td {
	.dashboard-action {
		vertical-align: top;
	}
}

.dashboard-actions {
	@media screen and (max-width: 1023px) {
		justify-content: flex-end;
		width: 100%;
	}

	li {
		list-style-type: none;
		display: inline-block;
		vertical-align: middle;
		padding-top: 6px;
		padding-bottom: 6px;

		&:not(:first-child) {
			padding-left: 20px;
		}

		&.res-toggle {
			padding-left: 0;

			@media screen and (max-width: 1100px) {
				padding-left: 20px;
			}
		}
	}
}

#btn-profile {
	object-fit: cover;
}

.dashboard-header {
	&>.container {
		width: 100%;
		max-width: 100%;
	}

}

.dashboard-filters {
	display: flex;
	padding: 0 20px;
}

.dashboard-header-inner {
	display: flex;
	justify-content: flex-end;
	padding: 8px 0;

}

.dashboard-search {
	padding: 6px 20px;
}

.copyright {
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 22px;
	text-align: center;
	color: #27316F;
}

.dashboard-footer {
	padding: 20px;
}

.sidebar-dropdown.active .sidebar-item {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.sidebar-dropdown-list {
	padding: 10px;
	background: #E8EBF2;
	border-radius: 0 0 10px 10px;
	display: none;
}

.sidebar-dropdown {
	cursor: pointer;

	&>.sidebar-item {
		padding-right: 38px;
		position: relative;

		&:after {
			content: "";
			height: 10px;
			width: 6px;
			background-image: url('/assets/images/dashboard/menu-arrow.svg');
			background-repeat: no-repeat;
			background-size: contain;
			background-position: center center;
			pointer-events: none;
			position: absolute;
			top: 50%;
			right: 10px;
			transform: translateY(-50%);
			transition: all 0.3s ease;
		}
	}

	&.active {
		.sidebar-dropdown-list {
			display: block;
		}

		&>.sidebar-item:after {
			transform: translateY(-50%) rotate(90deg);
		}
	}
}

.grey-dropdown {
	position: relative;

	select {
		background: #EEEEEE;
		border-radius: 5px;
		border: 0;
		outline: none;
		height: 42px;
		padding: 0 30px 0 10px;
		cursor: pointer;
		width: 100%;
	}

	&:after {
		content: "";
		height: 10px;
		width: 6px;
		background-image: url('/assets/images/dashboard/menu-arrow.svg');
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center center;
		pointer-events: none;
		position: absolute;
		top: 50%;
		right: 15px;
		transform: translateY(-50%) rotate(90deg);
		z-index: 2;
		pointer-events: none;
	}

	&:before {
		content: "";
		height: 42px;
		width: 30px;
		background-color: #EEEEEE;
		border-top-right-radius: 5px;
		border-bottom-right-radius: 5px;
		position: absolute;
		top: 0;
		right: 0;
		z-index: 1;
		pointer-events: none;
	}
}

.nocolor-dropdown {
	position: relative;

	select {
		background: #ffffff;
		border-radius: 5px;
		border: 0;
		outline: none;
		height: 42px;
		padding: 0 30px 0 10px;
		cursor: pointer;
	}

	&:after {
		content: "";
		height: 10px;
		width: 6px;
		background-image: url('/assets/images/dashboard/menu-arrow.svg');
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center center;
		pointer-events: none;
		position: absolute;
		top: 50%;
		right: 15px;
		transform: translateY(-50%) rotate(90deg);
		z-index: 2;
		pointer-events: none;
	}

	&:before {
		content: "";
		height: 42px;
		width: 30px;
		background-color: #ffffff;
		border-top-right-radius: 5px;
		border-bottom-right-radius: 5px;
		position: absolute;
		top: 0;
		right: 0;
		z-index: 1;
		pointer-events: none;
	}
}

.sortby {
	display: flex;
	align-items: center;

	&>span {
		font-style: normal;
		font-weight: 400;
		font-size: 18px;
		line-height: 22px;
		color: #27316F;
		white-space: nowrap;
		display: inline-block;
	}
}

.btn-toggle.dashboard-toggle {
	height: 42px;
	width: 42px;

	span {
		margin: 8px 0;
	}
}

.responsive-table {
	overflow: auto;
}

.table-odd-even {
	min-width: 420px;
}

.dashboard-heading {
	font-style: normal;
	font-weight: 600;
	font-size: 35px;
	line-height: 31px;
	color: #28305C;
	padding-top: 5px;
	padding-bottom: 5px;

	@media only screen and (max-width: 1200px) {
		font-size: 28px;
	}
}

.d-h-wrap {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin: 0 -10px;
	align-items: center;
	padding-bottom: 17px;

	@media only screen and (max-width: 1200px) {
		padding-bottom: 10px;
	}
}

.d-h-l,
.d-h-r {
	padding: 0 10px;
}

.dashboard-sub-filters {
	display: flex;
	align-items: center;

	li {
		list-style-type: none;
		display: inline-block;
		padding-top: 5px;
		padding-bottom: 5px;

		&:not(:last-child) {
			padding-right: 20px;
		}
	}

	.nocolor-dropdown {
		select {
			background: #f8f8f8;
			padding: 0 15px 0 10px;
		}

		&:before {
			background: #f8f8f8;
		}
	}
}

// .dashboard-heading {
// 	font-style: normal;
// 	font-weight: 500;
// 	font-size: 26px;
// 	line-height: 31px;
// 	color: #28305C;
// 	padding-top: 5px;
// 	padding-bottom: 5px;
// }
.d-h-wrap {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin: 0 -10px;
	align-items: center;
}

.d-h-l,
.d-h-r {
	padding: 0 10px;
}

.sub-filters-select {
	width: 100%;
	flex-wrap: wrap;
}

.dashboard-sub-filters {
	display: flex;
	align-items: center;

	li {
		list-style-type: none;
		display: inline-block;
		padding-top: 5px;
		padding-bottom: 5px;
		width: 100%;

		&:not(:last-child) {
			padding-right: 20px;
		}
	}

	.nocolor-dropdown {
		select {
			background: #f8f8f8;
			padding: 0 15px 0 10px;
			max-width: 150px;
		}

		&:before {
			background: #f8f8f8;
		}
	}
}

.multiple-select-sortby {
	flex-wrap: wrap;
	padding: 10px 0;
	border-radius: 4px;
	background-color: #f5f5f5;

	.roof-text {
		width: 100%;
		text-align: center;
		padding-bottom: 10px;
		border-bottom: 1px solid #ededed;
		margin-bottom: 10px;
	}

	.nocolor-dropdown {
		width: 100%;

		select {
			width: 100%;
			max-width: initial;
		}
	}
}

.project {
	padding: 15px;
	background: #FFFFFF;
	box-shadow: 0px 0px 23px rgba(0, 0, 0, 0.02);
	border-radius: 15px;
}

.project-img {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 225px;
	border-radius: 15px;
	overflow: hidden;

	&>a {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 225px;
		border-radius: 15px;
		overflow: hidden;
	}

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.dashboard-actions {
	display: flex;
}

.project-header-left,
.project-header-right,
.project-sub-left,
.project-sub-right {
	padding: 10px 5px;
}

.project-header,
.project-sub-details {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	margin: 0 -5px;
}

.project-name {
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: #28305C;
}

.project-date {
	display: inline-block;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #28305C;
	padding-left: 18px;
	position: relative;
	min-height: 17px;
	background-image: url('/assets/images/dashboard/calender-icon.svg');
	background-position: center left;
	background-repeat: no-repeat;
}

.project-body {
	&>p {
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 17px;
		color: rgba(40, 48, 92, 0.60);
	}
}

.project-sub-left h5,
.project-sub-right h5 {
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #28305C;
}

.project-location {
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #28305C;
	padding-left: 19px;
	position: relative;
	min-height: 17px;
	background-image: url('/assets/images/dashboard/location-icon.svg');
	background-position: center left;
	background-repeat: no-repeat;
}

.p-dollar {
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #28305C;
	padding-left: 19px;
	position: relative;
	min-height: 17px;
	background-image: url('/assets/images/dashboard/dollar.svg');
	background-position: center left;
	background-repeat: no-repeat;
}

.p-wallet {
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #28305C;
	padding-left: 19px;
	position: relative;
	min-height: 17px;
	background-image: url('/assets/images/dashboard/wallet.svg');
	background-position: center left;
	background-repeat: no-repeat;
}

.acc-header-inner {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	margin: 0 -5px;
}

.acc-h-cell {
	padding: 0 5px;
}

.btn-edit {
	background: transparent;
	border: 0;
	outline: none;
	margin-right: 15px;
	position: relative;
	top: -3px;
	cursor: pointer;
	outline: none;
	box-shadow: none;
}

.projects-list {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -12px;

	li {
		list-style-type: none;
		flex: 0 0 33.33%;
		padding: 0 12px 24px 12px;
	}
}

.form-toggles {
	list-style-type: none;
	padding: 0 7px;
	background: #E3E8F2;
	border-radius: 15px;

	li {
		padding: 14px 7px;
		display: inline-block;
	}
}

.form-toggle-btn {
	height: 52px;
	min-width: 108px;
	border-radius: 15px;
	padding: 0 15px;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	background: #e3e8f2;
	border: 0;
	outline: none;
	cursor: pointer;
	color: #27316F;

	.active {
		background: #27316f;
	}

	&.active {
		background: #27316f;
		color: #ffffff;
	}
}

.f-input {
	background: #F5F5F5 !important;
	border-radius: 5px;
	height: 50px;
	padding: 0 15px;
	width: 100%;
	border: 0;
	box-shadow: none !important;
	outline: none;
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	color: #28305C;
}

.f-area {
	background: #F5F5F5;
	border-radius: 5px;
	height: 100px;
	padding: 10px 15px;
	width: 100%;
	border: 0;
	box-shadow: none;
	outline: none;
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	color: #28305C;
	resize: none;
}

.f-field {
	margin-bottom: 20px;
	position: relative;

	.grey-dropdown {
		select {
			height: 50px;
			font-style: normal;
			font-weight: 500;
			font-size: 18px;
			color: #28305C;
			background-color: #f5f5f5;
		}

		&:before {
			background-color: #f5f5f5;
			height: 50px;
		}
	}

	&.has-icon {
		input {
			padding-right: 40px;
		}
	}
}

.f-row {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-end;
	margin: 0 -15px;

	&.alignTop {
		align-items: flex-start;
	}
}

.f-33 {
	flex: 0 0 33.33%;
	padding: 0 15px;
}

.f-16 {
	flex: 0 0 16.67%;
	padding: 0 15px;
}

.f-auto {
	flex: 1 1 auto;
	padding: 0 15px;
}

.f-icon {
	position: relative;
	height: 15px;
	width: 15px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 50%;
	right: 15px;
	pointer-events: none;
	transform: translateY(-50%);

	&.clickable {
		cursor: pointer;
		pointer-events: all;
		z-index: 2;
	}
}

.f-60 {
	flex: 0 0 60%;
	padding: 0 15px;
}

.f-40 {
	flex: 0 0 40%;
	padding: 0 15px;
}

.f-66 {
	flex: 0 0 66.66%;
	padding: 0 15px;
}

.f-100 {
	flex: 0 0 100%;
	padding: 0 15px;
}

.f-50 {
	flex: 0 0 50%;
	padding: 0 15px;
}

.f-25 {
	flex: 0 0 25%;
	padding: 0 15px;
}

.f-label {
	font-weight: 400;
	font-size: 16px;
	line-height: 1.3;
	display: block;
	color: #28305C;
	padding-bottom: 10px;
}

.m-b-25 {
	margin-bottom: 25px;
}

.checkbox.nolabel span {
	padding-left: 17px;
}

.checkbox-row {
	display: flex;
	justify-content: space-between;
	margin: 0 -5px;
	padding-bottom: 10px;
	flex-wrap: wrap;

	.checkbox {
		display: block;
	}
}

.checkbox-wrap {
	padding: 0 5px 5px 5px;

	.checkbox {
		span {
			&:before {
				top: 2px;
			}
		}
	}
}

.draggable-box {
	display: block;
	position: relative;
	background: #F5F5F5;
	border-radius: 5px;
	padding: 20px 20px 20px 70px;
	cursor: pointer;

	h4 {
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 19px;
		color: #3D5170;
		padding-bottom: 10px;
	}

	p {
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 17px;
		letter-spacing: 0.065em;
		color: rgba(61, 81, 112, 0.60);
		padding-bottom: 8px;
	}

	h6 {
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 17px;
		color: #5046BB;
	}

	input {
		display: none;
	}
}

.file-icon {
	height: 30px;
	width: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 20px;
}

.d-card-bottom {
	display: flex;
	margin: 0 -12px;
	flex-wrap: wrap;
}

.d-card-bottom-cell {
	padding: 0 12px 20px 12px;
}

.accordians {
	.accordion-item {
		background: #FFFFFF;
		box-shadow: 0px 0px 23px rgba(0, 0, 0, 0.02);
		border-radius: 15px;
		border: 1px solid rgba(39, 49, 111, 0.1);

		.accordion-button {
			background: #FFFFFF;
			border-radius: 15px !important;
			font-style: normal;
			font-weight: 500;
			font-size: 26px;
			color: #28305C;
			outline: none;
			box-shadow: none;
		}

		.accordion-button[aria-expanded="true"],
		.accordion-button:not(.collapsed) {
			border-bottom: 1px solid rgba(40, 48, 92, 0.1) !important;
			border-bottom-left-radius: 0 !important;
			border-bottom-right-radius: 0 !important;
		}
	}

	.accordion {
		.accordion-item {
			border-radius: 15px !important;

			&:not(:last-child) {
				margin-bottom: 25px;
			}
		}
	}

	.accordion-button {
		border-radius: 15px !important;
		padding: 23px;

		&::after {
			color: #28305C;
			background-image: url('/assets/images/dashboard/accordian-arrow.svg');
			background-position: center center;
		}
	}

}

.cm-dropdown [class*="pro-select-"]>.css-1s2u09g-control,
.cm-dropdown [class*="pro-select-"]>.css-1pahdxg-control {
	background: #F5F5F5;
	border-radius: 5px;
	height: 50px;
	padding: 0 15px;
	width: 100%;
	border: 0;
	box-shadow: none;
	outline: none;
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	color: #28305C;
	cursor: pointer;
}

.cm-dropdown.multi-check-drop {
	.pro-input-two {
		.css-1s2u09g-control {
			overflow-y: scroll;

			.css-1hb7zxy-IndicatorsContainer {
				min-height: 50px;
				max-height: 50px;
			}
		}
	}
}

.dashboard-table thead tr th,
.dashboard-table tbody tr td {
	background: #ffffff;
	padding: 10px 15px;
	border: 1px solid #D8DAE3;
	border-collapse: collapse;
	font-weight: 500;
	font-size: 16px;
	color: #28305C;
	height: 60px;
}

.dashboard-table {
	&>.responsive-table {
		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.04);
		border-radius: 15px;
	}

	table {
		border-collapse: separate;
		border-spacing: 0;
		width: 100%;

		tbody {
			tr {
				&:nth-child(even) {
					td {
						background: #F5F5F5;
					}
				}

				td {
					font-weight: 500;
				}

				&:last-child {
					td {
						&:first-child {
							border-bottom-left-radius: 15px;
						}

						&:last-child {
							border-bottom-right-radius: 15px;
						}
					}
				}
			}
		}

		thead {
			tr {
				th {
					font-weight: 600;
				}

				&:first-child {
					th {
						&:first-child {
							border-top-left-radius: 15px;
						}
					}
				}

				&:last-child {
					th {
						&:last-child {
							border-top-right-radius: 15px;
						}
					}
				}
			}
		}
	}
}

.dashboard-action {
	height: 25px;
	width: 25px;
	padding: 0;
	border: 0;
	line-height: 25px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	vertical-align: middle;
	margin: 0 2px;
	cursor: pointer;
	background: transparent;
	border-radius: 4px;

	&:hover {
		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
	}
}

.custom-pagination {
	li {
		list-style-type: none;
		display: inline-block;
		vertical-align: m;

		&:not(:last-child) {
			padding-right: 10px;
		}
	}
}

.btn-page a {
	background: #FFFFFF;
	border: 1px solid #D8DAE3;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.04);
	border-radius: 10px;
	height: 40px;
	min-width: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 5px 10px;

	svg {
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		top: 1px;
	}

	&.btn-next {
		svg {
			margin-left: 5px;
		}
	}

	&.btn-previous {
		svg {
			margin-right: 5px;
		}
	}
}

.btn-page.active a svg path,
.btn-page a:hover svg path {
	stroke: #ffffff;
}

.btn-page.active a,
.btn-page a:hover {
	background: #27316F;
	color: #ffffff;
}

ul.custom-pagination li.btn-page.active a {
	color: #fff;
}

.records-filter {
	display: flex;
	align-items: center;

	span {
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 19px;
	}

	.grey-dropdown {
		margin: 0 10px;
	}
}

.grey-dropdown {
	&.bg-white {
		border: 1px solid #D8DAE3;
		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.04);
		border-radius: 5px;

		select {
			background: #ffffff;
		}

		&:before {
			background: #ffffff;
		}
	}
}

.table-filters {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	margin: 0 -15px;
	padding-top: 15px;
}

.t-f-cell {
	padding: 15px;
	flex: 0 0 auto;
}

.custom-seachbox>input[type="search"] {
	height: 42px;
	border: 1.5px solid #CFDADD;
	border-radius: 10px;
	padding: 0 15px;
	width: 100%;
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	outline: none;
}


.custom-seachbox {
	position: relative;
	padding-right: 55px;
	max-width: 300px;

	.btn-search {
		padding: 0;
		width: 42px;
		min-width: 0;
		height: 42px;
		outline: none;
		box-shadow: none;
		position: absolute;
		top: 0;
		right: 0;
		border-radius: 10px;
	}
}

.owner {
	background: #FFFFFF;
	box-shadow: 0px 0px 23px rgba(0, 0, 0, 0.02);
	border-radius: 15px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	margin-bottom: 25px;
}

.owner-left,
.owner-right {
	padding: 17px;
}

.owner-left {
	position: relative;
	padding-left: 134px;
	min-height: 134px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex-wrap: wrap;

	h4 {
		font-style: normal;
		font-weight: 500;
		font-size: 22px;
		line-height: 26px;
		color: #28305C;
		padding-bottom: 5px;
	}

	p {
		font-style: normal;
		font-weight: 500;
		font-size: 20px;
		line-height: 24px;
		color: #28305C;
	}
}

.owner-img {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100px;
	width: 100px;
	position: absolute;
	top: 17px;
	left: 17px;
	object-fit: cover;
	border-radius: 6px;
	overflow: hidden;

	img {
		height: 100%;
		width: 100%;
		object-fit: cover;
	}
}

.owner-right {
	h5 {
		font-style: normal;
		font-weight: 500;
		font-size: 20px;
		line-height: 24px;
		color: #28305C;
	}
}

.dashboard-sm-sub-filters {
	.btn {
		height: 44px;
		border-radius: 8px;
	}

	.grey-dropdown {
		select {
			height: 44px;
			font-style: normal;
			font-weight: 500;
			font-size: 18px;
			border-radius: 8px;
		}

		&:before {
			height: 44px;
			border-top-right-radius: 8px;
			border-bottom-right-radius: 8px;
		}
	}
}

.project-view {
	background: #FFFFFF;
	box-shadow: 0px 0px 23px rgba(0, 0, 0, 0.02);
	border-radius: 15px;
	padding: 20px 20px 20px 360px;
	position: relative;
	min-height: 270px;
}

.project-view-img {
	border-radius: 8px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 320px;
	height: 230px;
	background: #f7f7f7;
	position: absolute;
	top: 20px;
	left: 20px;
	overflow: hidden;

	img {
		height: 100%;
		width: 100%;
		object-fit: cover;
	}
}

.project-view-time {
	position: relative;
	padding-left: 33px;
	font-style: normal;
	font-weight: 500;
	font-size: 22px;
	min-width: 23px;
	color: #27316F;
	display: flex;
	line-height: 22px;
	padding-top: 10px;
	padding-bottom: 10px;

	&>span {
		position: absolute;
		top: 10px;
		left: 0;
		height: 23px;
		width: 23px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.project-view-header {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	.project-view-header-l {
		display: flex;
		align-items: center;
	}
}

.project-view-list {
	li {
		list-style-type: none;
		display: inline-block;
		vertical-align: middle;
		padding-top: 5px;
		padding-bottom: 5px;

		&:not(:last-child) {
			padding-right: 20px;
		}
	}
}

.btn-m-action {
	background: #E3E8F2;
	border-radius: 15px;
	height: 54px;
	width: 54px;
	border: 0;
	outline: none;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: 0;
	cursor: pointer;

	&:hover {
		box-shadow: 0px 0px 4px 0px rgba(39, 49, 111, 0.28);
	}
}

.p-infos {
	padding-bottom: 35px;

	li {
		display: inline-block;
		list-style-type: none;

		&:not(:last-child) {
			padding-right: 30px;
		}
	}
}

.p-info {
	min-height: 13px;
	padding-left: 20px;
	position: relative;

	p {
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 17px;
		margin: 0;
		color: #28305C;
	}

	&>span {
		position: absolute;
		top: 2px;
		left: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 13px;
		width: 13px;
	}
}

.project-view-header {
	padding-bottom: 20px;
}

.project-info-sub {
	li {
		display: inline-block;
		list-style-type: none;
		padding-bottom: 10px;

		&:not(:last-child) {
			padding-right: 30px;
		}

		h6 {
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 17px;
			color: #28305C;
		}

		h5 {
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 19px;
			color: #28305C;
		}
	}
}

.bid-hd-l {
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 24px;
	color: #27316F;
}

.bid-hd-sm {
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: #27316F;
}

.v-count {
	height: 10px;
	width: 10px;
	font-style: normal;
	font-weight: 500;
	font-size: 7px;
	line-height: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #F8F8F8;
	border: 1px solid #27316F;
	border-radius: 50%;
	position: absolute;
	top: 0;
	right: 0;
}

.dashboard-action {
	position: relative;
}

.sample-gallery {
	margin: 0 -15px;
	display: flex;
	flex-wrap: wrap;

	li {
		list-style-type: none;
		padding: 15px;
		flex: 0 0 20%;

		&>span {
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 8px;
			overflow: hidden;
			width: 100%;
		}

		span {
			img {
				object-fit: cover;
				height: 100%;
				width: 100%;
			}
		}
	}
}

.qa-wrap {
	position: relative;
	padding-right: 100px;

	h4 {
		font-style: normal;
		font-weight: 500;
		font-size: 20px;
		line-height: 28px;
		color: #27316F;
		margin-bottom: 15px;

		&>span {
			font-style: normal;
			font-weight: 500;
			font-size: 20px;
			line-height: 28px;
			color: #27316F;
		}
	}

	p {
		font-style: normal;
		font-weight: 400;
		font-size: 18px;
		line-height: 28px;
		color: #27316F;
	}
}

.qa-list {
	li {
		list-style-type: none;
		padding: 15px 0;

		&:not(:last-child) {
			border-bottom: 1px solid rgba(40, 48, 92, 0.1) !important;
		}
	}
}

.d-t {
	position: absolute;
	top: 50%;
	right: 0;
	transform: translateY(-50%);

	h5 {
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 19px;
		color: #28305C;
	}
}

.pdf-info {
	display: inline-flex;
	background: #F5F5F5;
	border-radius: 5px;
	padding: 20px 20px 20px 20px;
	position: relative;
	min-height: 80px;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	&:hover {
		background: #dedddd;
	}

	&>span {
		height: 80px;
		position: absolute;
		top: 20px;
		left: 20px;
	}

	p {
		font-style: normal;
		font-weight: 500;
		font-size: 20px;
		color: #27316F;

		img {
			width: 30px;
			margin-left: 10px;
		}
	}
}

.desc-info-detail {
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 24px;
	color: #27316F;
	padding-bottom: 20px;
}

.desc-info-heading {
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: #27316F;
	padding-bottom: 5px;
}

.desc-info {
	&:not(:last-child) {
		padding-bottom: 30px;
	}
}

.link-btn {
	background: transparent;
	border: 0;
	outline: none;
	padding: 0;
	box-shadow: none;
	text-decoration: underline;
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 22px;
}

.desc-info-md-detail {
	font-style: normal;
	font-weight: 500;
	font-size: 22px;
	line-height: 26px;
	color: #27316F;
	padding-bottom: 20px;
}

.noAccPadding .dashboard-table table thead tr:first-child th:first-child,
.noAccPadding .dashboard-table table thead tr:last-child th:last-child {
	border-radius: 0;
}

.noAccPadding .dashboard-table thead tr th,
.noAccPadding .dashboard-table tbody tr td {
	border-color: rgba(40, 48, 92, 0.1);
}

.noAccPadding {
	.accordion-body {
		padding: 0;
	}
}

.accordians .accordion-item.noAccPadding .accordion-button[aria-expanded="true"],
.accordians .accordion-item.noAccPadding .accordion-button:not(.collapsed) {
	border: 0 !important;
}

.modal-actions {
	flex: 1 1 100%;
	margin: 0 -10px;

	li {
		list-style-type: none;
		display: inline-block;
		padding: 0 10px;
	}
}

.modal-body,
.modal-footer {
	padding-left: 30px;
	padding-right: 30px;

	button {
		height: 40px;
		font-size: 14px;
	}
}

.modal-header {
	position: relative;
	padding: 15px 60px 15px 30px;
	min-height: 40px;

	.btn-close {
		position: absolute;
		top: 50%;
		right: 20px;
		transform: translateY(-50%);
		margin: 0 !important;
		color: #1F2658;
		background-image: url('/assets/images/modal-close.svg');
		opacity: 1;
		background-size: 25px;
	}
}

.modal-content {
	box-shadow: 0px 0px 74px rgba(0, 0, 0, 0.10);
	border-radius: 15px;

	.modal-body {
		font-size: 20px;
		font-weight: 600;
	}
}

.modal-footer {
	border: 0;
	padding-bottom: 30px;
}

.modal-title {
	font-style: normal;
	font-weight: 500;
	font-size: 26px;
	line-height: 31px;
	color: #28305C;
}

.custom-inner-header {
	flex: 0 0 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;

	.nocolor-dropdown {
		select {
			padding-right: 10px;
		}
	}
}

.m-b-0 {
	margin-bottom: 0 !important;
}

.contractor-heading {
	font-style: normal;
	font-weight: 500;
	font-size: 26px;
	line-height: 31px;
	color: #28305C;
}

.custom-inner-header {
	flex: 0 0 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;

	.nocolor-dropdown {
		select {
			padding-right: 10px;
		}
	}
}

.contractor-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 0 -10px;
}

.contractor-header-l,
.contractor-header-r {
	padding: 10px;
}

.contractor-wrapper {
	padding-top: 10px;
}

.contractor-list {
	margin-top: 15px;
	max-height: 300px;
	overflow: auto;

	li {
		list-style-type: none;
		position: relative;
		padding-left: 40px;

		&:not(last-child) {
			padding-bottom: 20px;
		}
	}
}

.contractor-check {
	position: absolute;
	top: 10px;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
}

.contractor-details {
	background: #F8F8F8;
	border-radius: 10px;
	padding: 7px;
}

.noBorder {
	border: 0;
}

.bidder-header {
	position: relative;
	min-height: 150px;
	padding-left: 150px;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	padding-bottom: 15px;
}

.bidder-img {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 121px;
	height: 121px;
	overflow: hidden;
	border-radius: 6px;
	position: absolute;
	top: 0;
	left: 0;

	img {
		height: 100%;
		width: 100%;
		object-fit: cover;
	}
}

.bidder-wrapper {
	margin-top: -20px;
}

.btn-play {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	cursor: pointer;
	background: transparent;
	border: 0;
	outline: none;
	box-shadow: none;
	border-radius: 50%;
	height: 50px;
	width: 50px;
}

.btn-pdf {
	position: absolute;
	top: 15px;
	right: 15px;
	cursor: pointer;
	background: transparent;
	border: 0;
	outline: none;
	box-shadow: none;
	width: 23px;
	height: 28px;
}

.inspection-gallery {
	margin: 0 -12px;

	&>li {
		.project {
			padding: 0;
			position: relative;
		}
	}
}

.project-img>video {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.form-inner-heading {
	margin-bottom: 15px;
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 22px;
	color: #28305C;
}

.head-tabs {
	padding-bottom: 10px;

	li {
		list-style-type: none;
		padding-right: 20px;
		padding-bottom: 20px;
		display: inline-block;
		vertical-align: middle;
	}
}

.tab-item {
	background-color: #ffffff;
	color: #27316F;
	border-radius: 15px;
	padding: 0 13px;
	height: 52px;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.04);
	border: 0;

	&.active,
	&:hover {
		background: #27316F;
		color: #ffffff;
	}
}

.payment-client {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 76px;
	height: 76px;
	border-radius: 10px;
	overflow: hidden;
	background: #ffffff;
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
	border: 5px solid #28305C;

	&.noborder {
		border: 0;
	}

	img {
		height: 100%;
		width: 100%;
		object-fit: cover;
	}
}

.payments-details {
	position: relative;
	padding-left: 90px;
	min-height: 76px;

	h4 {
		font-style: normal;
		font-weight: 500;
		font-size: 22px;
		line-height: 26px;
		color: #28305C;
		padding-bottom: 20px;

		span {
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 19px;
			color: #28305C;
			opacity: 0.5;
		}
	}

	h5 {
		font-style: normal;
		font-weight: 500;
		font-size: 22px;
		line-height: 26px;
		color: #28305C;
	}
}

.faq-list {
	li {
		list-style-type: none;
		padding-bottom: 20px;

		&:not(:last-child) {
			.faq-item {
				border-bottom: 1px solid #EEEEEE;
				padding-bottom: 20px;
			}
		}
	}
}

.faq-item {
	padding-right: 80px;
	position: relative;

	&>h4 {
		font-style: normal;
		font-weight: 500;
		font-size: 20px;
		line-height: 28px;
		color: #27316F;
		padding-bottom: 10px;
	}

	&>p {
		font-style: normal;
		font-weight: 400;
		font-size: 18px;
		line-height: 28px;
		color: #27316F;
	}
}

.quick-actions {
	position: absolute;
	top: 0;
	right: 0;

	.dashboard-action {
		width: 30px;
		height: 30px;

		img {
			width: 20px;
		}
	}
}

// extra css for image uploader start
.imageTag {
	display: flex;
	justify-content: space-between;
}

.imageProjectInput {
	display: inline;
}

//  extra css for image uploader end

.inline-actions {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}

.available-templates {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -15px;

	li {
		list-style-type: none;
		padding: 0px 15px 25px 15px;
		flex: 0 0 33.33%;
	}
}

.template-text {
	background: #F5F5F5;
	border-radius: 5px;
	padding: 15px 20px 20px 20px;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 22px;
	color: #28305C;
}

.template-name {
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: #28305C;
	padding-bottom: 10px;
}

.template {
	&:hover {
		.template-text {
			background: #dddddd;
		}
	}
}


.toggle-switch {
	display: inline-flex;
	align-items: center;
	justify-content: flex-end;

	&>h6 {
		color: #28305C;
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 17px;
		text-align: right;
		padding-right: 5px;
	}

	&>input[type="checkbox"]:checked {
		&~.activate {
			display: none;
		}

		&~.deactivate {
			display: inline-block;
		}

		&~.toggle-bar {
			background: #27316f;

			&:after {
				left: 29px;
			}
		}
	}

	&>.deactivate {
		opacity: 0.3;
		display: none;
	}

	&>input[type="checkbox"] {
		display: none;
	}

	.toggle-bar {
		display: inline-block;
		border-radius: 12px;
		height: 24px;
		width: 53px;
		border: 1px solid #dddddd;
		background: #dddddd;
		position: relative;
		cursor: pointer;

		&:after {
			content: "";
			height: 22px;
			width: 22px;
			border-radius: 50%;
			background: #ffffff;
			position: absolute;
			top: 0;
			left: 0;
			transition: all 0.3s ease;
		}
	}

	.available-templates {
		li {
			flex: 0 0 33.33%;
		}
	}

}

.m-r-10 {
	margin-right: 10px;
}

.m-r-15 {
	margin-right: 15px;
}

.blue-bid-details {
	min-height: 43px;
	padding-left: 59px;
}

.blue-bid-icon {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 43px;
	width: 43px;
	object-fit: cover;
	border-radius: 4px;
	position: absolute;
	top: 0;
	left: 0;
	background-color: #28305C;
}

.fullWidth {
	width: 100%;
}

.sm-heading {
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 24px;
	color: #28305C;
	padding-bottom: 12px;
}

.documents-list {
	&>li {
		list-style-type: none;
		padding-bottom: 17px;
	}
}

.document {
	background: #F8F8F8;
	border-radius: 10px;
	padding: 20px 100px 20px 60px;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	z-index: 1;
}

.document-icon {
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 50%;
	left: 20px;
	transform: translateY(-50%);
	height: 25px;
	width: 22px;
}

.btn-download {
	background: transparent;
	outline: none;
	box-shadow: none;
	border: 0;
	cursor: pointer;
}

.btn-dot {
	&>button {
		background-color: transparent !important;
		min-width: 0;
		padding: 0;
		width: 25px;
		height: 25px;
		border-radius: 0;
		background-image: url('/assets/images/dashboard/dots.svg') !important;
		background-repeat: no-repeat !important;
		background-position: center center !important;

		&:after {
			display: none !important;
		}
	}
}

.document-actions {
	display: flex;
	margin: 0 -5px;
	position: absolute;
	top: 50%;
	right: 10px;
	transform: translateY(-50%);
}

.d-action {
	padding: 5px;
}

.document-title {
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: #28305C;
	padding-bottom: 3px;
}

.document-date {
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #28305C;
}

.upload-docs {
	border: 1px solid #E1E1E1;
	filter: drop-shadow(0px 0px 23px rgba(0, 0, 0, 0.02));
	border-radius: 15px;
	padding: 20px 20px 0 20px;
}

.upload-area {
	border: 1px dashed #E1E1E1;
	border-radius: 10px;
	padding: 15px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	margin-bottom: 25px;
	width: 100%;
	overflow: hidden;
	cursor: pointer;
	min-height: 275px;

	input[type="file"] {
		display: none;
	}
}

.upload-doc-desc {
	text-align: center;

	h5 {
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 19px;
		color: #3D5170;
		padding-bottom: 10px;
	}

	span {
		padding-bottom: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	p {
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 17px;
		letter-spacing: 0.065em;
		color: #3D5170;
		padding-bottom: 8px;
	}

	h6 {
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 17px;
		color: #037BFF;
	}
}

.sm-des {
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	letter-spacing: 0.065em;
	color: #3D5170;
	opacity: 0.6;
	padding-bottom: 15px;
}

.error {
	color: red;
	font-size: 14px;
}

.terms-desc {
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 28px;
	color: #27316F;

	&:not(:last-child) {
		margin-bottom: 15px;
	}
}

.al-icon {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 93px;
	width: 93px;
	background: #27316F;
	border-radius: 13px;
	position: absolute;
	top: 10px;
	left: 10px;
}

.alternate-list-item {
	padding: 10px;
	border: 1px solid rgba(39, 49, 111, 0.1);
	border-radius: 20px;
	min-height: 115px;
	position: relative;
	padding-left: 123px;
	display: flex;
	flex-direction: column;
	justify-content: center;

	p {
		font-style: normal;
		font-weight: 600;
		font-size: 18px;
		line-height: 22px;
		color: #1F2547;
	}

	h5 {
		font-style: normal;
		font-weight: 600;
		font-size: 24px;
		line-height: 29px;
		color: #1F2547;
		padding-bottom: 10px;
	}

	&.right {
		padding-left: 10px;
		padding-right: 123px;
		text-align: right;

		.al-icon {
			right: 10px;
			left: auto;
		}
	}
}

.alternate-listings {
	li {
		list-style-type: none;
		padding-bottom: 30px;
	}
}

.btn-center-groups {
	margin: 0 -15px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
}

.btn-el {
	padding: 10px;
}

.invoice-wrapper {
	overflow: auto;
}

.btn-settings {
	padding: 0;
	width: 42px;
	min-width: 0;
	height: 42px;
	outline: none;
	box-shadow: none;
	border-radius: 10px;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	border: 0;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	background: #E3E8F2 !important;
	color: #27316F;

	&:hover,
	&.active {
		background: #27316F !important;

		svg {
			path {
				fill: #ffffff;
			}
		}
	}
}

.notification-dropdown.grey {
	.dropdown-toggle {

		padding: 0;
		width: 42px;
		min-width: 0;
		height: 42px;
		outline: none;
		box-shadow: none;
		border-radius: 10px;
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		border: 0;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		transition: all 0.3s ease;
		-webkit-transition: all 0.3s ease;
		-moz-transition: all 0.3s ease;
		background: #E3E8F2 !important;
		color: #27316F;

		&:hover,
		&.active {
			background: #27316F !important;

			svg {
				path {
					stroke: #ffffff;
				}
			}
		}
	}

	.dropdown-toggle[aria-expanded="true"] {
		padding: 0;
		width: 42px;
		min-width: 0;
		height: 42px;
		outline: none;
		box-shadow: none;
		border-radius: 10px;
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		border: 0;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		transition: all 0.3s ease;
		-webkit-transition: all 0.3s ease;
		-moz-transition: all 0.3s ease;
		background: #27316F !important;

		svg {
			path {
				stroke: #ffffff;
			}
		}
	}
}


.grey {
	.btn {
		background: #E3E8F2 !important;
		color: #27316F;
	}
}

.owners-projects-list {
	&>li {
		flex: 0 0 100%;
		padding-top: 0;
		padding-bottom: 0;

		.project {
			position: relative;
			padding-left: 205px;
			padding-top: 15px;
			padding-right: 0;
			padding-bottom: 15px;

			.project-img {
				position: absolute;
				top: 15px;
				left: 0;
				width: 190px;
				height: 100px;
			}

			.project-sub-details {
				justify-content: flex-start;
			}
		}

		&:not(:last-child) {
			.project {
				border-bottom: 1px solid #EEEEEE;
			}
		}
	}
}

.owners-projects-list .project-sub-left,
.owners-projects-list .project-sub-cell,
.owners-projects-list .project-sub-right {
	padding-right: 20px;
}

.v-wrapper {
	padding: 25px 0;
	background: #f8f8f8;
	border-radius: 20px 0 0 20px;
}

.projects-tabs {
	display: inline-flex;
	align-items: center;
	background: #E3E8F2;
	border-radius: 15px;
	padding: 5px;
	flex-wrap: wrap;

	li {
		list-style-type: none;
		padding: 5px;
	}

	.tab-item {
		background: #e3e8f2;
		box-shadow: none;
	}
}

.projects-tabs .tab-item.active,
ul.projects-tabs .tab-item:hover {
	background: #27316F;
}

.ds-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;

	.ds-cell {
		.dashboard-title {
			padding: 10px 0;
		}
	}
}

.accordians.dark-accordians .accordion-item,
.accordians.dark-accordians .accordion-button {
	background: #F8F8F8;
}

.accordians {
	&.dark-accordians {
		.accordion-item {
			.accordion-button {
				font-size: 20px;
				font-weight: 500;
			}
		}

		.terms-desc {
			font-size: 18px;
			line-height: 28px;
		}
	}
}


.terms-item {
	h5 {
		color: #3D5170;
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 19px;
		letter-spacing: 0.01em;
		padding-bottom: 15px;
	}

	p {
		color: #3D5170;
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 19px;
		letter-spacing: 0.01em;
	}

	&:not(:last-child) {
		padding-bottom: 30px;
	}
}

.help-box {
	border-radius: 15px;
	background: rgba(225, 225, 225, 0.2);
	padding: 25px;

	h5 {
		letter-spacing: 0.01em;
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 20px;
		color: #2E2E5D;
		padding-bottom: 10px;
	}

	p {
		letter-spacing: 0.01em;
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
		color: #2E2E5D;
		padding-bottom: 20px;
	}

	ul {
		li {
			list-style-type: none;
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 20px;
			letter-spacing: 0.01em;
			color: #2E2E5D;
			padding-bottom: 10px;
		}
	}
}

.owner-profile-wrapper {
	display: flex;
	margin: 0 -10px;

	.desc-info-heading {
		letter-spacing: 0.065em;
		color: rgba(61, 81, 112, 0.6);
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 17px;
	}

	.desc-info-detail {
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 19px;
		letter-spacing: 0.065em;
		color: #3D5170;
		padding-bottom: 0;
	}
}

.owner-profile-img {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border: 0.5px solid rgba(33, 68, 105, 0.2);
	filter: drop-shadow(0px 5px 16px rgba(57, 54, 190, 0.05));
	border-radius: 12px;
	height: 120px;
	width: 120px;
	overflow: hidden;
	position: absolute;
	top: 0;
	left: 0;

	&>img {
		height: 100%;
		width: 100%;
		object-fit: cover;
	}

	input[type="file"] {
		display: none;
	}
}

.edit-profile {
	position: absolute;
	bottom: 0;
	right: 0;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	height: 26px;
	width: 26px;
	overflow: hidden;
	cursor: pointer;
}

.owner-name {
	font-style: normal;
	font-weight: 500;
	font-size: 28.6316px;
	line-height: 34px;
	letter-spacing: 0.065em;
	color: #3D5170;
	padding: 10px 0;
}

.owner-profile-header {
	position: relative;
	padding-left: 150px;
	min-height: 140px;
	padding-bottom: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.u-p-l {
	flex: 1 1 auto;
}

.u-p-r {
	flex: 0 0 auto;
}

.u-p-l,
.u-p-r {
	padding: 0 10px;
}

.sort-icon {
	white-space: nowrap;
}

.add-more-list {
	font-size: 16px;
	display: flex !important;
	justify-content: space-between;
	align-items: center;
	color: #7f87a4;
	padding: 0 0.5rem !important;

	h5 {
		font-size: inherit;
		color: inherit;
	}
}

.icons-size {
	width: 36px;

	&.icons-input-margin {
		margin: 8px 0 0 8px;
	}
}

.form-content {
	.f-33 {
		.form-group {
			width: 100%;
		}
	}
}

.sub-card-header {
	padding: 20px 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;

	h6 {
		font-size: 1.5rem;
	}
}

.minus-btton-absolute {
	position: absolute;
	top: 0.5rem;
	right: 1.1rem;
}

.high-low-value {
	background: #F5F5F5 !important;
	border-radius: 5px;
	padding: 0 15px;
	width: 100%;
	border: 0;
	box-shadow: none !important;
	outline: none;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	color: #28305C;

	p {
		padding: 10px 0;
	}

	.h-l-dropdown {
		border-top: 1px solid #ededed;
		padding: 10px 15px;
		margin: 0 -15px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		input {
			background: #cecece !important;
			border-radius: 5px;
			padding: 0 5px;
			width: 100%;
			border: 0;
			height: 36px;
			box-shadow: none !important;
			outline: none;
			font-style: normal;
			font-weight: 500;
			font-size: 12px;
			color: #28305C;
		}

		>div {
			width: 45%;

			input {
				background: #cecece !important;
				border-radius: 5px;
				padding: 0 5px;
				width: 100%;
				border: 0;
				height: 24px;
				box-shadow: none !important;
				outline: none;
				font-style: normal;
				font-weight: 500;
				font-size: 12px;
				color: #28305C;
			}

			input[type='radio'] {
				width: auto;
				height: auto;
			}

			p {
				font-size: 12px;
				padding: 0;
			}
		}
	}
}

@media only screen and (max-width: 1100px) {
	.services-list {
		margin: 0 -15px;
		@include justify-content(center);

		li {
			padding: 15px;
			@include flex(0, 0, 50%);
		}
	}

	.footer-links li:not(:last-child) {
		padding-right: 30px;
	}

	.footer-left {
		flex: 0 0 200px;
	}

	.login-left,
	.login-right {
		flex: 0 0 100%;
	}

	.login-box {
		padding: 30px 20px;
	}

	.login-left {
		padding: 30px;
	}

	.login-left-wrapper {
		max-width: 100%;

		.brand {
			margin-bottom: 40px;
		}
	}

	.bid-label {
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 17px;
	}

	.dashboard-sidebar {
		position: fixed;
		overflow: auto;
		left: -110%;
		transition: all 0.3s ease;
	}

	.dashboard {
		padding-left: 0;
	}

	.active-sidebar {
		.dashboard-sidebar {
			left: 0;
		}

		.dashboard-toggle {
			span {
				&:first-child {
					transform: rotate(-45deg);
					transform-origin: 20px 0px;
				}

				&:nth-child(2) {
					display: none;
				}

				&:last-child {
					transform: rotate(45deg);
					transform-origin: 24px 0px;
				}
			}
		}

		.overlay {
			display: block;
		}
	}


	.dashboard-tiles {
		li {
			flex: 0 0 50%;
		}
	}

	.res-toggle {
		display: block;

		.dashboard-toggle {
			display: inline-block;

			.dashboard-actions {
				li {
					&:not(:first-child) {
						padding-left: 0;
					}

					&:not(:last-child) {
						padding-right: 15px;
					}

					&.res-toggle {
						display: block;

						.dashboard-toggle {
							display: inline-block;
						}
					}

				}
			}
		}
	}
}


@media only screen and (max-width: 1025px) {

	.container,
	.inner-container {
		max-width: 100%;
	}

	.btn-toggle {
		display: inline-block;
	}

	.dashboard-menu {
		position: fixed;
		top: 0;
		left: -100%;
		bottom: 0;
		width: 100%;
		max-width: 260px;
		z-index: 999;
		background-color: $primaryWhiteColor;
		@include flex-direction(column);
		padding: 20px;
		@include transition(all, 0.3s, ease);
		overflow: auto;

		li {
			width: 100%;
			padding: 0;

			&:not(:last-child) {
				padding: 0;
			}

			.menu-item {
				display: block;
				padding: 15px;
			}

			&.active .menu-item {
				background: $primaryBlueColor;
				color: #80D6D8;
				border-radius: 6px;
				font-weight: 700;
			}

			&.responsive-close {
				display: block;
			}

			&.btn-item {
				padding: 18px 0 !important;
			}
		}

	}

	.main {
		&.toggled {
			.dashboard-menu {
				left: 0;
			}

			.btn-toggle {
				span {
					&:first-child {
						transform: rotate(-45deg);
						transform-origin: 21px 2px;
					}

					&:nth-child(2) {
						display: none;
					}

					&:last-child {
						transform: rotate(45deg);
						transform-origin: 19px 0px;
					}
				}
			}

			.overlay {
				display: block;
			}
		}
	}

	.d-card-col-68,
	.d-card-col-32 {
		flex: 0 0 100%;
	}

	.dashboard-header-inner {
		flex-wrap: wrap;
		flex-direction: row;
	}

	.dashboard-title {
		flex: 0 0 100%;
		padding: 6px 20px;
	}

	.dashboard-filters {
		flex: 0 0 100%;
		justify-content: space-between;
	}

	.dashboard-search {
		flex: 0 0 50%;
		padding: 6px 0;
	}

	.dashboard-actions {
		li {
			padding: 6px 0;
			padding-left: 20px;
		}
	}

	.r-m-b-30 {
		margin-bottom: 30px;
	}

	.d-card-heading,
	.d-card-actions {
		padding-top: 5px;
		padding-bottom: 5px;
	}

	.projects-list {
		li {
			flex: 0 0 50%;
		}
	}

	.owners-projects-list>li {
		flex: 0 0 100%;
	}

	.accordians .accordion-item .accordion-button {
		font-size: 21px;
	}

	.owner-dashboard-actions {
		flex-wrap: wrap;
		display: flex;

		li {
			flex: 0 0 33.33% !important;
		}
	}
}



@media only screen and (max-width: 991px) {

	.f-33,
	.f-25,
	.f-16 {
		flex: 0 0 50%;
	}

	.f-50,
	.f-66 {
		flex: 0 0 100%;
	}

	.sample-gallery {
		li {
			flex: 0 0 25%;
		}
	}

	.available-templates {
		li {
			flex: 0 0 50%;
		}
	}

	.contact-us-wrapper {
		max-width: 100%;
	}
}

@media only screen and (max-width: 767px) {
	.services-list {
		@include justify-content(center);

		li {
			@include flex(0, 0, 100%);
		}
	}

	.footer-inner {
		@include flex-wrap(wrap);

		.top-links {
			text-align: center;
		}

		.footer-left,
		.footer-right {
			@include flex(0, 0, 100%);
		}

		.footer-left {
			@include flexbox;
			@include justify-content(center);
		}
	}

	.footer-links {
		text-align: center;
		padding-top: 30px;
	}

	.overlay-inner {
		padding: 30px 15px;
	}

	.dashboard-search {
		order: 2;
		flex: 0 0 100%;
	}

	.dashboard-actions {
		order: 1;
		flex: 0 0 100%;
		justify-content: space-between;
		display: flex;
		margin: 0 -10px;

		li {
			padding: 10px;
		}
	}

	.dashboard-filters {
		flex-wrap: wrap;
	}

	.search-box {
		max-width: none;
	}

	.dashboard-tiles li,
	.maps-list li {
		flex: 0 0 100%;
	}

	.projects-list {
		li {
			flex: 0 0 100%;
		}
	}

	.f-33,
	.f-25,
	.f-16 {
		flex: 0 0 100%;
	}

	.accordians .accordion-item .accordion-button {
		font-size: 18px;
	}

	.custom-seachbox {
		max-width: 100%;
	}

	.project-view {
		padding-left: 20px;
	}

	.project-view-img {
		position: static;
	}

	.sample-gallery {
		li {
			flex: 0 0 33.33%;
		}
	}

	.qa-wrap {
		padding-right: 0;
	}

	.d-t {
		position: static;
		margin-top: 30px;
	}

	.bidder-header {
		padding-left: 15px;
	}

	.bidder-img {
		position: static;
		margin-bottom: 30px;
	}

	.f-60,
	.f-40 {
		flex: 0 0 100%;
	}

	.faq-item {
		padding-right: 0;
	}

	.quick-actions {
		position: static;
		padding-top: 15px;
	}

	.available-templates {
		li {
			flex: 0 0 100%;
		}
	}

	.owners-projects-list>li {
		flex: 0 0 50%;
	}

	.owners-projects-list>li .project {
		padding: 15px;

		.project-img {
			position: static;
			width: 100%;
			height: auto;
		}
	}

	.owner-profile-wrapper {
		flex-wrap: wrap;

		.u-p-l,
		.u-p-r {
			flex: 0 0 100%;
		}

		.owner-profile-actions {
			padding-top: 25px;
		}

		.owner-profile-header {
			padding-left: 0;
		}

		.owner-profile-img {
			position: static;
			margin-bottom: 15px;
		}
	}

	.login-right {
		display: none;
	}

	.dashboard-sub-filters {
		flex-wrap: wrap;
	}
}


@media only screen and (max-width: 580px) {
	.dashboard-actions {
		flex-wrap: wrap;
		justify-content: space-between;
		margin: 0 -5px;

		li {
			flex: 0 0 auto;
			padding: 10px 5px !important;
		}
	}

	.sample-gallery {
		li {
			flex: 0 0 50%;
		}
	}

	.alternate-list-item.right .al-icon,
	.al-icon {
		margin: 0 auto 20px auto;
		position: static;
	}

	.alternate-list-item,
	.alternate-list-item.right {
		text-align: center;
		padding: 10px;
	}

	.owners-projects-list>li {
		flex: 0 0 100%;
	}

}

@media only screen and (max-width: 420px) {
	.owner-left {
		padding-left: 17px;

		.owner-img {
			position: static;
			margin-bottom: 15px;
		}
	}

}

//***********************Estimator Assistance*********************************
.estimator-container {
	display: flex;
	justify-content: space-between;
}

.estimator-blocks {
	width: 45%;
	height: 100%;
}

.estimator-arrow {
	padding-top: 230px;

	span {
		i {
			&.bi {
				font-size: 2rem;
			}
		}
	}
}

.nav-tabs {
	border-bottom: none;
	margin-bottom: 15px;
}

.nav-tabs .nav-link {
	font-weight: 600;
}

.main-head {
	font-weight: 900;
}

/***** page404 *****/

.page-not-found {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	background: #f7f7f7;

	.card {
		background: #f7f7f7;
		text-align: center;
		padding: 40px;
		width: 70%;

		h3 {
			font-size: 18px;
		}

		h3 span {
			font-size: 10vw;
			font-weight: 900;
			width: 60%;
			display: block;
			margin: auto;
		}

		h2 {
			font-weight: 600;
			width: 60%;
			margin: 15px auto;
			font-size: 2vw;
		}

		button {
			height: 45px;
		}
	}
}

.notification-dropdown {
	#btn-notification {
		span {
			position: absolute;
			top: -10px;
			right: -10px;
			font-size: 14px;
			background: red;
			text-align: center;
			height: 20px;
			min-width: 20px;
			border-radius: 30px;
			padding: 0px 3px;
		}
	}
}

.btn-est-assi {
	font-weight: 500;
	position: absolute;
	right: 6%;
	border: none;
	padding: 15px;
	background: #e3e8f2;
}

.btn-est-assi:hover {
	background: #ced2db;
}

// custom-form style start

form.custom-form {
	padding: 20px;
	border: 1px solid #e9e9e9;
	border-radius: 20px;
	margin-bottom: 15px;
}

form.custom-form p {
	font-weight: 400;
	font-size: 16px;
	line-height: 1.6;
	color: #28305C;
}

form.custom-form h3,
form.custom-form h6 {
	color: #28305C;
}

.custom-form input {
	background: #fff !important;
	border: 1px solid #E3E8F2;
	border-radius: 5px;
}

.custom-form .css-6j8wv5-Input {
	margin: 0;
}


.readable-data {
	padding: 15px;
	border: 1px solid #e9e9e9;
	border-radius: 20px;
	margin-bottom: 15px;
}

.readable-data .dashboard-inner {
	background: transparent;
	padding: 0 0 20px;
	font-size: 26px;
	font-weight: 600;
}

.readable-data label {
	font-weight: 500;
	font-size: 16px;
	line-height: 1.3;
	color: #28305C;
	margin-bottom: 6px;
	display: block;
}

.readable-data data {
	font-weight: 600;
	font-size: 18px;
	line-height: 1.3;
	color: #000;
}

.readable-data .form-group {
	margin-bottom: 20px;
	border-bottom: 1px solid #e9e9e9;
	padding-bottom: 5px;
}


// bid-submit table style
.bid-outer-wrap tr td:last-child {
	width: 130px;
}

.bid-outer-wrap table {
	width: 100%;
}

.bid-outer-wrap table th,
.bid-outer-wrap table td {
	border: 1px solid #E3E8F2;
	padding: 10px;
}

.bid-outer-wrap table input {
	height: 32px;
	font-size: 15px;
	padding: 0 10px;
}

.bid-outer-wrap table .f-field,
.bid-outer-wrap table .f-label {
	margin: 0;
	padding: 0;
}

.bid-outer-wrap .bid-time {
	display: flex;
	display: -ms-flexbox;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	padding: 15px 0;
}

.bid-outer-wrap .bid-time label {
	display: none;
}

.bid-outer-wrap .bid-time .f-field {
	margin: 0;
	padding: 0 5px;
}

.bid-outer-wrap .bid-time input {
	height: 35px;
	width: 185px;
	font-size: 13px;
	padding: 0 10px;
}

.bid-outer-wrap .bid-time input:focus-visible {
	outline: 0;
	border-color: #86b7fe;
}

.bid-outer-wrap table .th-bg {
	background: #E3E8F2;
}

.bid-outer-wrap .bid-time input.f-days {
	width: 50px;
}

.btn-group1 .btn-est-assi {
	position: relative;
}

.btn-group1 {
	margin-left: auto;
	position: absolute;
	right: 6%;
}

// table changes
.dashboard-table .table-update {
	overflow: hidden;
}

.dashboard-table .table-update th,
.dashboard-table .table-update td {
	border-bottom: 0;
	border-right: 0;
	border-radius: 0 !important;
	height: auto;
}

.dashboard-table .table-update th:last-child,
.dashboard-table .table-update td:last-child {
	border-right: 1px solid #D8DAE3;
}

.dashboard-table .table-update tr:last-child td {
	border-bottom: 1px solid #D8DAE3;
}

.dashboard-table .table-update th:first-child {
	border-top-left-radius: 15px !important;
	width: 100px;
}

.dashboard-table .table-update th:last-child {
	border-top-right-radius: 15px !important;
	width: 100px;
}

.dashboard-table .table-update tr:last-child td:first-child {
	border-bottom-left-radius: 15px !important;
}

.dashboard-table .table-update tr:last-child td:last-child {
	border-bottom-right-radius: 15px !important;
}

// end table changes

// result changes
.bid-result .dashboard-table .table-update th:first-child,
.bid-result .dashboard-table .table-update th:last-child {
	width: auto;
}

.bid-result .dashboard-table td {
	word-break: break-all;
}

.listing .dashboard-table td {
	word-break: normal;
}

.bid-result .dashboard-table td .link {
	cursor: pointer;
	width: 40px;
	display: inline-block;
}

.expand-area {
	padding: 10px;
}

.expand-area p {
	line-height: 1.3;
	margin-bottom: 5px;
}

.expand-area .btn-wrapper {
	margin-top: 15px;
}

.expand-area .btn-wrapper .btn {
	height: 45px;
	margin-right: 10px;
}

.bid-result .dashboard-table th,
.dashboard-table .dark-header tr th,
.diff-table table tr th {
	// word-break: break-all;
	color: var(--primary-white-color, #ffffff);
	background: #27316f;
	border-color: #0d195e;
}

.btn.btn-red {
	background: #e21e25;
}

.btn.btn-red:hover {
	background: var(--primary-blue-color, #27316F);
}

.estimator-arrow .btn {
	height: 44px;
	border-radius: 8px;
}

.diff-table table tbody tr:nth-child(even) td {
	background: transparent;
}

.pro-des .dashboard-inner {
	background: transparent;
}

// end result changes

// graph-report style start
.custom-horizontal-bar {
	border: 1px solid #D9D9D9;
	margin: 5px -25px 25px;
	border-radius: 8px;
}

.graph-form .form-content {
	padding: 10px;
}

.graph-form .f-input,
.graph-form .cm-dropdown [class*="pro-select-"]>.css-1s2u09g-control,
.graph-form .cm-dropdown [class*="pro-select-"]>.css-1pahdxg-control {
	background: transparent !important;
	border: 1px solid #E6E6E6;
	border-radius: 8px;
	padding-right: 0;
}

.graph-form .cm-dropdown [class*="pro-select-"]>.css-1s2u09g-control span.css-1okebmr-indicatorSeparator,
.graph-form .cm-dropdown [class*="pro-select-"]>.css-1pahdxg-control span.css-1okebmr-indicatorSeparator {
	background-color: transparent;
}

.graph-form .cm-dropdown .css-tlfecz-indicatorContainer {
	color: #28305C;
}

.graph-form .cm-dropdown .css-tlfecz-indicatorContainer svg {
	width: 26px;
	height: 26px;
}

.f-h4 {
	font-weight: 500;
}

.appselectlist {
	position: relative;
}

.appinput {
	background-color: transparent !important;
	background-image: url(../images/select-arrow.png);
	background-repeat: no-repeat;
	background-size: auto;
	background-position: calc(100% - 10px);
	border: 1px solid #E6E6E6;
	border-radius: 8px;
	height: 50px;
	line-height: 48px;
	padding: 0 15px;
	width: 100%;
	outline: none;
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	color: #28305C;
	display: inline-block;
	cursor: pointer;
}

.appselectlist .option-list {
	position: absolute;
	background: #fff;
	border: 1px solid #DEDEDE;
	border-radius: 10px;
	margin-top: 5px;
	width: 100%;
	opacity: 0;
	visibility: hidden;
}

.showapplist .option-list {
	opacity: 1;
	visibility: visible;
}

.appselectlist .option-list ul {
	list-style: none;
	padding: 15px 10px;
}

.appselectlist .option-list h4 {
	font-weight: 500;
	font-size: 16px;
	color: #28305C;
	padding: 15px;
	border-bottom: 1px solid #EEEEEE;
}

.appselectlist .option-list ul label.checkbox span {
	font-weight: 400;
	font-size: 16px;
	color: #28305C;
}

.appselectlist .option-list ul .checkbox-row {
	padding: 0;
}

.appselectlist .option-list ul .checkbox-wrap {
	padding: 0;
}

.appselectlist .option-list ul li {
	padding: 5px 10px;
}

.appselectlist .option-list ul li:hover {
	background: #E3E8F2;
}

.appselectlist .option-list .btn-footer {
	padding: 0 15px 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.appselectlist .option-list .btn-footer .btn {
	font-size: 15px;
	min-width: auto;
	height: 42px;
}

// show list
.showullist ul {
	list-style: none;
	margin: 20px 0;
}

.showullist ul li {
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-weight: 500;
	font-size: 14px;
	color: #28305C;
}

.showullist ul li input {
	width: 107px;
	height: 26px;
	font-weight: 500;
	font-size: 12px;
	color: #28305C;
	opacity: 0.5;
}

.form-content .showullist ul li .form-group {
	width: auto;
}

.showullist ul li .form-group .f-field {
	margin: 0;
}

.showullist .close-wrap {
	border: 0.5px solid rgba(39, 49, 110, 0.3);
	border-radius: 3px;
	cursor: pointer;
	display: inline-block;
	width: 18px;
	height: 18px;
	line-height: 16px;
	margin-right: 8px;
}

.showullist .close-wrap i::before {
	color: #232360;
	font-weight: 800 !important;
}

.showullist .close-wrap:hover {
	background: #E6E6E6;
}


.graph-form .checkbox span {
	color: #28305C;
}

.paddleft {
	padding-left: 20px;
}

.paddleft .checkbox span {
	font-size: 12px;
}

.graph-form .checkbox span:before {
	border: 2px solid rgba(39, 49, 110, 0.3);
}

.graph-form .checkbox.radio-checkbox input[type="radio"]:checked~span:before {
	border: 4px solid #27316E;
	background: transparent;
}

.generate-btn {
	margin: 20px;
	margin-left: auto;
	width: 185px;
	font-weight: 500;
	font-size: 18px;
}

.chart-wrap .recharts-wrapper {
	width: 100% !important;
}

.chart-detail {
	display: flex;
	padding: 0 5px;
}

.chart-detail ul {
	list-style: none;
	display: flex;
	flex-wrap: wrap;
}

.chart-detail ul li.full-wrap {
	width: 100%;
	font-weight: 500;
	font-size: 14px;
	color: #28305C;
	margin-bottom: 2px;
}

.chart-detail .left,
.chart-detail .right {
	background: rgba(227, 232, 242, 0.2);
	border: 1px solid #E3E8F2;
	border-radius: 12px;
	padding: 15px 12px 0;
	margin-bottom: 30px;
}

.chart-detail .left {
	width: 70%;
	margin-right: 20px;
}

.chart-detail .right {
	width: 30%;
}

h6.chart-head {
	font-weight: 500;
	font-size: 18px;
	color: #28305C;
	margin-bottom: 16px;
	padding: 0 5px;
}

.chart-detail ul .label {
	font-weight: 400;
	font-size: 14px;
	color: #28305C;
}

.chart-detail ul .data {
	font-weight: 500;
	font-size: 14px;
	color: #28305C;
}

.chart-detail ul li {
	margin-bottom: 15px;
	width: 20%;
}

.chart-detail .right li {
	width: 50%;
	padding-left: 15px;
}

.chart-detail .right li:first-child {
	border-right: 1px solid #E3E8F2;
	width: 30%;
}

.chart-detail ul .sub-data {
	font-weight: 500;
	font-size: 12px;
	color: #28305C;
	opacity: 0.75;
}

.chart-detail .right ul .label {
	font-weight: 600;
}

.chart-detail,
h6.chart-head {
	padding: 0;
}

// graph result stye ends




// responsive start
@media only screen and (max-width: 991px) {
	.bid-outer-wrap tr td:last-child {
		width: 100px;
	}

	.bid-outer-wrap .bid-time input {
		margin: 5px 0;
	}

	form.custom-form .button-section {
		text-align: center;
		margin: 10px 0;
	}
}

@media only screen and (max-width: 767px) {
	.chart-detail {
		display: block;
	}

	.chart-detail .left,
	.chart-detail .right {
		width: 100%;
		margin-bottom: 15px;
	}

	.chart-detail ul li {
		width: 33.333%;
	}
}


// responsive start for all pages issue
@media only screen and (max-width: 1400px) {
	.d-t-left h4 {
		font-size: 42px;
	}

	.d-tile-footer h5 {
		font-size: 28px;
	}

	.d-tile-footer span {
		font-size: 16px;
	}

	.d-t-left p {
		font-size: 16px;
	}

	.tile-icon {
		height: 65px;
		width: 65px;
	}

	.tile-icon img {
		width: 35px;
	}

	.d-card-heading {
		font-size: 28px;
		padding: 0 10px 0;
	}

	.card-sub-heading {
		font-size: 16px;
	}

	.dashboard-footer {
		padding: 15px;
	}

	.copyright {
		font-size: 16px;
	}
}

@media only screen and (max-width: 1200px) {
	.dashboard-tiles li {
		padding: 0 6px 12px;
	}

	.d-card-col-32 {
		padding: 0 6px;
	}

	.d-card-col-68 {
		padding: 0 6px;
	}

	.d-card-col-68 .d-card {
		margin-bottom: 20px !important;
	}

	.dashboard-footer {
		padding: 10px;
	}

	.copyright {
		font-size: 14px;
	}
}

@media only screen and (max-width: 991px) {
	.dashboard-inner h4.dashboard-title {
		padding: 0 0 12px;
	}

	.d-t-left h4 {
		font-size: 34px;
	}

	.d-tile-footer h5 {
		font-size: 22px;
	}

	.d-tile-footer span {
		font-size: 15px;
	}

	.d-t-left p {
		font-size: 15px;
	}

	.d-card-heading {
		font-size: 25px;
		padding: 0 10px 0;
	}

	.card-sub-heading {
		font-size: 15px;
	}
}

@media only screen and (max-width: 768px) {
	.dashboard-actions .dashboard-sidebar li {
		padding: 0 !important;
		display: block;
	}

	.dashboard-filters {
		padding: 0;
	}
}