.mainFileDes {
    display: flex;
    justify-content: space-between;
    border: 1px dotted #000;
    padding: 5px 10px;
    border-radius: 30px;
    margin-bottom: 10px;
}

.mainFileDes span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
    @media screen and (max-width: 525px) {
        max-width: 150px;
    }
    @media screen and (max-width: 350px) {
        max-width: 100px;
    }
}

.mainFile img{
    height: 80px;
    width: 80px;
}